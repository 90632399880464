import { useEffect, useMemo, useRef, useState } from "react";



export default function useCountdownTime({ events = [] }){
    const [ active, setActive ] = useState(null)
    const [ remaining, setRemaining ] = useState(0)
    const timer = useRef();
    
    
    const time = useMemo(() => {
        return {
            days: Math.floor(remaining / (1000 * 60 * 60 * 24)),
            hours: Math.floor((remaining / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((remaining / 1000 / 60) % 60),
            seconds: Math.floor((remaining / 1000) % 60)
        }

    }, [ remaining ])



    function updateTimer(endBy){
        const timeRemaining = Date.parse(endBy) - Date.parse(new Date());
        setRemaining(timeRemaining)

        if( timeRemaining <= 0 ){
            clearInterval(timer.current);
        }
    }


    useEffect(() => {
        (events || []).forEach(event => {
            const start = Date.parse(event?.startDate);
            const end = Date.parse(event?.endDate);
            const now = Date.parse(new Date());
    
            if (end > now && start <= now) {
                timer.current = setInterval(() => updateTimer(event?.endDate), 1000);
                setActive(event);
            }
        });
    
        return () => {
            clearInterval(timer.current);
        };
    }, [events]);
    



    return {
        time,
        active
    }

}