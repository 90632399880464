import Style from './CountdownPencilBanner.module.scss'
import utils from 'styles/globals/utils.module.scss';

import clsx from 'clsx';
import Link from 'next/link';
import Countdown from 'components/display/Countdown';
//import { IconClock } from 'components/icons/IconClock';
import { GetCountdownBanner } from 'contexts/AdContext';
import Skeleton from 'components/feedback/Skeleton';
import PencilBanner from '../PencilBanner';
import useCountdownTime from 'custom_hooks/useCountdownTime';
import ContentImage from 'components/display/ContentImage';

import { useEffect, useState} from 'react';


function CountdownPencilBanner(
    image
){
    const [events, setEvents] = useState(null);

    useEffect(() => {
        const fetchEvents = async () => {
            const data = await GetCountdownBanner();
            setEvents(data);
        };
        fetchEvents();
    }, []);

    const countdown = useCountdownTime({ events: events || [] });
    const iconImage = countdown?.active?.image?.[0]

    if( !events ){
        return(
            <aside role="banner" className={clsx(Style.block, utils[`bg_${countdown?.active?.backgroundColor?.class}`])}>
                <Skeleton width={"5%"} height={8} className={Style.loader} /> &nbsp;
                <Skeleton width={"5%"} height={8} className={Style.loader} /> &nbsp;
                <Skeleton width={"15%"} height={8} className={Style.loader} /> &nbsp;
                <Skeleton width={"10%"} height={8} className={Style.loader} />
            </aside>
        )
    }


    if( !countdown?.active && events ){
        return <PencilBanner />
    }


    return (
        <aside role="banner" className={clsx(Style.block, utils[`bg_${countdown?.active?.backgroundColor?.class}`], utils[`text_${countdown?.active?.textColor?.class}`])}>            

            <Link href={countdown?.active?.href ?? "#"} className={clsx(Style.link, Style.flexContainer)}>

            <ContentImage
                    img={iconImage}
                    title="Front Icon"
                    className={Style.iconImage}
                    width={25}
                    height={25}
                    priority={true}
                />
                <div className={Style.contentStack}>
                    <div 
                        className={clsx(Style.body, utils[`text_${countdown?.active?.textColor?.class}`])} 
                        dangerouslySetInnerHTML={{ __html: countdown?.active?.body }} 
                    />

                    <div 
                        className={clsx( 
                            utils[`text_${countdown?.active?.textColor?.class}`], 
                            utils[`bg_${countdown?.active?.backgroundColor?.class}`]
                        )}
                    >
                        {/* <IconClock
                            fill={`${countdown?.active?.textColor?.color?.[0]}`}
                            width="30" 
                            height="30" 
                            className={Style.icon} 
                        /> */}

                        <Countdown 
                            {...{ 
                                time: countdown.time,
                                active: countdown.active 
                            }}
                        />
                    </div>
                </div>
                <ContentImage
                    img={iconImage}
                    title="Front Icon"
                    className={Style.iconImage}
                    width={25}
                    height={25}
                    priority={true}
                />
            </Link>
        </aside>
    );
}


export default CountdownPencilBanner;