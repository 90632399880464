/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import TimeString from "./TimeString";

import Style from './Countdown.module.scss'


function Countdown({ 
    time,
    timeStrings = ["days", "hours", "minutes", "seconds"] 
}){

    return(
        <ol className={Style.block}>
            {timeStrings.map((timeString) => {
                return(
                    <TimeString 
                        key={timeString}
                        {...{ 
                            label: timeString, 
                            value: time[timeString] 
                        }} 
                    />
                )
            })}
        </ol>
    )
}



Countdown.propTypes = {
    time: PropTypes.object,
    timeStrings: PropTypes.array
}


export default Countdown;