"use client"

import clsx from 'clsx';
import { GetPencilBannerData } from 'services/header/HeaderContext.service';
import Link from 'next/link';
import utils from 'styles/globals/utils.module.scss';
import { useEffect, useState } from 'react';
import Style from '../PencilBanner/PencilBanner.module.scss'


function PencilBanner(){
    const [ pencilData, setPencilData ] = useState({}) 

    useEffect(() => {
        GetPencilBannerData().then((response) => {
            setPencilData(response)
        })

    }, [])

    return (
        <aside className={clsx(Style.block, utils[`bg_${pencilData?.backgroundColor}`] )}>
            <Link
                href={pencilData?.href ?? "#"}
                title="pencil banner"
                className={clsx(Style.promoMessage, utils[`text_${pencilData?.textColor}`] )}
            >
                <div dangerouslySetInnerHTML={{ __html: pencilData?.body }}  />
            </Link>
        </aside>
    );
}


export default PencilBanner;