
/**
 * Gets Countdowns from the CMS
 * @param {string} category - category name 
 * @returns 
 */

export default function GetCountdown(){
    return  `query getCountdown {
                entries(section: "countdown") {
                    id
                    ... on countdown_default_Entry {
                        id
                        body
                        href
                        endDate
                        startDate
                        image {
                            height
                            filename
                            srcset(sizes: "400")
                            width
                            url
                        }
                        textColor {
                            class
                            color
                        }
                        backgroundColor {
                            class
                            color
                        }
                    }
                }
            }`
}