import GraphCMS from "services/cms/GraphQL.service";
import GetAdvertisement from "services/cms/queries/GetAdvertisement";
import GetCountdown from "services/cms/queries/GetCountdown";

const cmsGraphQl = new GraphCMS();


// Gets All Blog Category Entries
async function GetAd(categoryUri, slug) {
	const adQuery = GetAdvertisement(categoryUri, slug);
	return await cmsGraphQl.post(adQuery).then((res) => {
        return res?.data.entries
    })
}


async function GetCountdownBanner(){
    const countdownQuery = GetCountdown();
    const response = await cmsGraphQl.post(countdownQuery);
    //console.log("GetCountdownBanner response:", response);
    return response?.data?.entries;
}


export {
    GetAd,
    GetCountdownBanner
}