import PropTypes from 'prop-types';
import Style from './Countdown.module.scss'


function TimeString({ label, value }){
    return(
        <li className={Style.item}>
            <strong className={Style.time}>
                {value}
            </strong>

            <small className={Style.label}>
                {label}
            </small>
        </li>
    )
}


TimeString.propTypes = {
    label: PropTypes.string,
    value: PropTypes.number
}



export default TimeString;